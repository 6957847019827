@tailwind base;
@tailwind components;
@tailwind utilities;

body > svg {
  display: none;
}

@layer base {
  * {
    @apply focus-visible:outline outline-1 focus-visible:outline-2 outline-offset-2 focus-visible:outline-primary-7;
  }
}

@layer utilities {
  .dominant-baseline-central {
    dominant-baseline: central;
  }

  .stroke-linecap-round {
    stroke-linecap: round;
  }

  .text-anchor-middle {
    text-anchor: middle;
  }
}

@layer components {
  .calendar-cell {
    @apply text-center relative focus-within:relative focus-within:z-20;
  }

  .calendar-caption {
    @apply flex justify-center relative items-center;
  }

  .calendar-day-hidden {
    @apply invisible;
  }

  .calendar-head-row {
    @apply flex;
  }

  .calendar-months {
    @apply flex flex-col sm:flex-row;
  }

  .calendar-nav {
    @apply flex items-center;
  }

  .calendar-nav-button {
    @apply inline-flex items-center justify-center;
  }

  .calendar-nav-button-next {
    @apply absolute;
  }

  .calendar-nav-button-previous {
    @apply absolute;
  }

  .calendar-row {
    @apply flex w-full;
  }

  .calendar-table {
    @apply table w-full border-collapse;
  }

  .popover-content {
    @apply z-50 will-change-transform data-exiting:animate-out data-closed:animate-out data-exiting:fade-out-0 data-closed:fade-out-0 data-exiting:zoom-out-95 data-closed:zoom-out-95 data-entering:animate-in data-open:animate-in data-entering:fade-in-0 data-open:fade-in-0 data-entering:zoom-in-95 data-open:zoom-in-95 data-placement-bottom:slide-in-from-top-1 data-side-bottom:slide-in-from-top-1 data-placement-left:slide-in-from-right-1 data-side-left:slide-in-from-right-1 data-placement-right:slide-in-from-left-1 data-side-right:slide-in-from-left-1 data-placement-top:slide-in-from-bottom-1 data-side-top:slide-in-from-bottom-1;
  }

  .tooltip {
    @apply z-50 select-none data-delayed-open:animate-in data-delayed-open:fade-in data-delayed-open:data-side-bottom:slide-in-from-bottom-1 data-delayed-open:data-side-left:slide-in-from-left-1 data-delayed-open:data-side-right:slide-in-from-right-1 data-delayed-open:data-side-top:slide-in-from-top-1 will-change-transform;
  }
}
